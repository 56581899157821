html, body {width: auto!important; overflow-x: hidden!important} 

.My-Site {
  text-align: center;
}

@font-face {
  font-family: 'Black-Diamond';
  src: local('Black-Diamond'), url(./Black-Diamond.woff) format('woff');
}

.Site-header {
  background: rgb(241,204,185);
  background: linear-gradient(180deg, rgba(241,204,185,1) 0%, rgba(255,140,140,1) 100%);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.Welcome{
  flex-direction: row;
  display: inline-block;
}

h1{
  position:relative;
  top:20px;
  padding-right:30px;
  font-size:28px;
  width:110px;
  display: inline-block;
}

.myName{
  font-family:"Black-Diamond";
  font-size:60px;
  display: inline-block;
}

.icon{
  float:right;
  width:30px;
  height:30px;
  margin-right:10px;
}
 .iconBar{
   margin-top:10px;
   margin-right:50px;
   width:100%;
   float:right;
 }

.Description{
  font-size: 16px;
  width:500px;
  text-align: center;
}

hr{
  border: 0;
  height: 1px;
  background-color:black;
}

.resume{
  width:800px;
  padding-bottom: 8%;
}

.resumeItem{
  background-color: black;
  background: rgb(204, 204, 204); /* Fallback for older browsers without RGBA-support */
  background: rgba(20, 20, 20, 0.3);
  color:white;
  font-size:12px;
  -webkit-box-shadow: 3px 3px 1px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 3px 3px 1px 0px rgba(0,0,0,0.75);
  box-shadow: 3px 3px 1px 0px rgba(0,0,0,0.75);
  width:300px;
  height:220px;
  padding-left:10px;
  padding-right:10px;
  float:left;
  margin-left:40px;
  margin-top:30px; 
}

.itemTitle{
  font-size:24px;
  font-family: Arial, Helvetica, sans-serif;
  margin-top:10px;
  margin-bottom:10px;
  font-weight: bold;
}

.itemRole{
  font-size:14px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.itemDescription{
  font-size:12px;
  font-family: Arial, Helvetica, sans-serif;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .Welcome{
    flex-direction: row;
    width:360px;
    display: inline-block;
    margin-top:0;
    padding-left:10%;
  }

  .Site-header{
    flex-direction: column;
  }

  .Description{
    width:60%;
  }

  .resume{
    width: 70%;
    margin:0;
    margin-bottom:10%;
    padding-right:5%;
  }

  .resumeItem{
    float:left;
    margin-top:30px;
    margin-left:0;
    width:100%;
    height:230px;
    padding-left:10px;
    padding-right:10px;
  }
}